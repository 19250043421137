import React, { useEffect, useState } from "react";
import LayoutNew from "../components/LayoutNew";
import { Helmet } from "react-helmet";
import axios from "axios";
import {splitDescription, calculateYearlyPricePerMonth} from "../utils";
import HaveQuestion from "./new-pages/common/HaveQuestion";
import MessageModal from "./new-pages/common/MessageModal";
import bubbleIcon from "../../static/images/speech-bubble.png";
import superIcon1 from "../../static/images/super-icon1.png";
import superIcon2 from "../../static/images/super-icon2.png";
import RatingReviewInternet from "./new-pages/common/RatingReviewInternet";
import CommonQuestionInternet from "./new-pages/common/CommonQuestionInternet";
import { productCatalogS3Bucket} from "../config";
import LastSection from "./new-pages/common/LastSection";


const FrontInternetPage = () => {
    const [tabType, setTabType] = useState(false);
    const [messagePopup, setMessagePopup] = useState(false);
    const [plans, setPlans] = useState([{
        id: 0,
        api_name: "Loading...",
        description: "\u2714\ufe0fLoading...",
        speed: "Loading...",
        provider: "Loading...",
        billing: [
            {
                monthly: {
                    api_name: "Loading...",
                    display_name: "Internet",
                    invoice_name: "Internet",
                    price: 60.00
                },
                yearly: {
                    api_name: "Loading...",
                    display_name: "Internet Yearly",
                    invoice_name: "Internet Yearly",
                    price: 60.00
                }
            }
        ],
        addons: [],
        resources: [],
        status: "ACTIVE"
    }]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios(
                    productCatalogS3Bucket + "snapshots/latest/services/internet/plans.json"
                );
                setPlans(response.data);
            } catch (err) {
                console.error("Failed to fetch internet plans:", err);
            }
        };
        fetchData();
    },[])

    return (
        <LayoutNew>
            <Helmet>
                <title>Internet – Purple Cow Internet 💜🐄</title>
            </Helmet>

            <section className="tv-section4">
                <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">Pricing is simple</h2>
                    <p className="p2 text-black text-center mb-8">& doesn't go up after a year!</p>
                    <div className="tab-type new-tab">
                        <span onClick={() => setTabType(false)}
                              className={tabType ? "tab-type__option " : "tab-type__option active"}>Pay Monthly</span>
                        <span onClick={() => setTabType(true)}
                              className={tabType ? "tab-type__option active" : "tab-type__option "}>Pay Yearly</span>
                    </div>
                    <div className="selectPlan addon-plan-1">
                        {plans.map((plan, index) => (
                            plan.status != "ACTIVE" ? null : (
                                <div key={index} className="selectPlan__optional">
                                    <label className="selectPlan__box phone-page">
                                        <input
                                            type="checkbox"
                                            name="selectPlan__"
                                            checked={false}
                                        />
                                        <div className="selectPlan__box-inner">
                                            <h3 className="h3">{plan.name}</h3>
                                            <h2 className="h2"
                                                dangerouslySetInnerHTML={{__html: !tabType ? '$' + plan.billing[0].monthly.price + ' <sup>/ month</sup>' : '<del class="delete-price">$' + plan.billing[0].monthly.price + '</del> $' + calculateYearlyPricePerMonth(plan.billing[0].yearly.price) + ' <sup>/ month</sup>'}}>
                                            </h2>
                                            <ul className="plan-list">
                                                {splitDescription(plan.description).map((line, index) => (
                                                    <li key={index}>{line}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </label>
                                </div>
                            )
                        ))}
                    </div>
                    <p className="p2 bottom">Prices and service may vary based on area. One-time setup and shipping of
                        $55.</p>
                </div>
            </section>

            <section className="internet-section2">
                <div className="container mx-auto px-4 bg-transparent">
                    <h2 className="h2">Amazing internet</h2>
                    <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="relative">
                            <div className="gray-round">
                                <img src={superIcon1} alt="internet" className="internet-sec2-icon"/>
                            </div>
                            <h4 className="h4">Gigabit Speeds</h4>
                            <p className="p2 text-center">Watch, download and play as much as you want</p>
                        </div>
                        <div className="relative">
                            <div className="gray-round">
                                <span>99.8%</span>
                            </div>
                            <h4 className="h4">Extremely Reliable</h4>
                            <p className="p2 text-center">With a 99.8% uptime we are one of the best in the industry</p>
                        </div>
                        <div className="relative">
                            <div className="gray-round">
                                <img src={superIcon2} alt="internet" className="internet-sec2-icon"/>
                            </div>
                            <h4 className="h4">Customer Service</h4>
                            <p className="p2 text-center">Ranked #1 in Canada by google customer reviews</p>
                        </div>
                    </div>
                </div>
            </section>

            <RatingReviewInternet
                title="Ranked best internet service provider in Halifax"
                subtitle="Actually the highest rated in Canada"
            />

            <CommonQuestionInternet/>

            <HaveQuestion closepopup={setMessagePopup}/>

            <LastSection title="Time to jump the fence?" />
            <div className="contact-us-parent">
                <div className="contact-click-box" onClick={() => setMessagePopup(true)}>
                    <img src={bubbleIcon} alt="bubble" className="bubble-icon"/>
                </div>
            </div>
            {messagePopup ? (<MessageModal closepopup={setMessagePopup}></MessageModal>) : null}
        </LayoutNew>
    )
}

export default FrontInternetPage